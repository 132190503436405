<template>
  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base v-if="breadcrumb" :breadcrumb="breadcrumb" :title="'StockReportDetails'">
      <template v-slot:right-end>
        <!--        <vs-button class="mt-3 py-4 px-3" color="primary"-->
        <!--                   @click="openAddInventory">{{$t('AddInventory')}}-->
        <!--        </vs-button>-->
      </template>
    </breadcrumb-base>
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="soldPins && soldPins.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--               class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
        </div>
      </div>
    </transition>
    <div class="mx-6">
      <h2 class="mb-4"><strong>{{ $t('SoldPins') }}</strong></h2>
      <div class="plain-table w-full">
        <table>
          <tr>
            <th>{{ $t('SNo') }}</th>
            <th>{{ $t('Pin') }}</th>
            <th>{{ $t('SoldBy') }}</th>
            <th>{{ $t('SoldById') }}</th>
            <th>{{ $t('SoldTime') }}</th>
          </tr>
          <tbody>
          <tr :data="pintr" :key="pinindextr" v-for="(pintr, pinindextr) in soldPins">
            <td>
              {{ pinindextr + 1 }}
            </td>
            <td>
              <strong>{{ pintr.pin }}</strong>
            </td>
            <td>
              {{ pintr.sold_by_name }}
            </td>
            <td>
              {{ pintr.sold_by_id }}
            </td>
            <td>
              {{ pintr.sold_by_at | dateFormat }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SoldPinsListPage',
  data() {
    return {
      soldPins: null,
      errorFetching: false,
      breadcrumb: null,
    }
  },
  props: {
    configId: {
      type: Number | String,
      default: '',
    },
    batch: {
      type: String,
      default: '',
    },
  },
  methods: {
    fetchBatchSoldPins() {
      const payload = {
        'batch': this.batch,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchBatchSoldPins', { payload })
        .then((data) => {
          this.soldPins = data.sold_pins
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchBatchSoldPins()
    this.breadcrumb = [
      { title: 'Home', i18n: 'Home', url: '/' },
      { title: 'Stock Report', i18n: 'StockReport', url: '/reports-stock' },
      { title: 'Stock Report Details', i18n: 'StockReportDetails', url: `stock/details/${this.configId}` },
      { title: 'Sold Pins', i18n: 'SoldPins', active: true },
    ]
  },
}
</script>

<style scoped lang="scss">

.plain-table {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: #eee;
  }
  th {
    background: #333;
    color: white;
    font-weight: bold;
  }
  td, th {
    padding: 6px;
    border: 1px solid #ccc;
    text-align: left;
  }
}
</style>
